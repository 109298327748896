import { Link, useNavigate } from "react-router-dom";
import "./home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from "swiper/core";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import Header from "../components/Header";

function Home() {
    SwiperCore.use([Autoplay]);
    const swiperParams = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        }
    };
    const swiperBanner = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        slidesPerView: 4,
        spaceBetween: 10,
        grabCursor: true
    };
    const swiperSports = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        slidesPerView: 3,
        spaceBetween: 20,
        grabCursor: true
    };
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const url = `${process.env.REACT_APP_API_URL}/auth/getUser`;
    function formatNumber(number) {
        return number < 10 ? `0${number}` : `${number}`;
    }
    function formatTime(timeString) {
        var dateTime = new Date(timeString);
        var hours = formatNumber(dateTime.getHours());
        var minutes = formatNumber(dateTime.getMinutes());
        var timeFormat = `${hours}:${minutes}`;
        var day = formatNumber(dateTime.getDate());
        var month = formatNumber(dateTime.getMonth() + 1);
        var year = formatNumber(dateTime.getFullYear());
        var dateFormat = `<div>${day}/${month}/${year}</div>`;
        return timeFormat;
    }
    function formatDay(timeString) {
        var dateTime = new Date(timeString);
        var day = formatNumber(dateTime.getDate());
        var month = formatNumber(dateTime.getMonth() + 1);
        var year = formatNumber(dateTime.getFullYear());
        var dateFormat = `${day}/${month}/${year}`;
        return dateFormat;
    }
    function handleImageError(e, num) {
        e.target.onerror = null;
        e.target.src = `${num == 1 ? "https://static.uni247.xyz/images-v3/srcompetitor%20away.svg" : "https://static.uni247.xyz/images-v3/srcompetitor%20home.svg"}`;
    }
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const navigate = useNavigate();
    useEffect(() => {
        axios
            .get(url, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
        axios.post(`${process.env.REACT_APP_API_URL}/casino/create`, {});
    }, []);

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        axios.get(url, {}).then((res) => {
            setProfile(res.data.data);
            localStorage.setItem("profile", JSON.stringify(res.data.data));
        });
    }, []);

    const [product, setProduct] = useState([]);
    const [finalData, setFinalData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const config = {
                    headers: {
                        Authorization:
                            "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiODg1YWI5NDQtNzhmMS00NmZkLTgzNTItNDc1MTJlM2VhOTY3IiwicGxheWVyX2lkIjoiaW5kaWFfMy4wX2Zvcl9kaXNwbGF5IiwibWVyY2hhbnRfY29kZSI6ImJhY2tvZmZpY2UtZDllMzIiLCJpc3N1ZWRfYXQiOiIyMDIyLTEwLTI4VDA4OjA5OjUzLjU0ODE0ODcyNloiLCJleHBpcmVzX2F0IjoiMjEyMi0xMC0yOFQwMDowMDowMC4wMDAwMDE2ODdaIn0.WEL6t_UEf8KglL-p_OLQe5xsHhDgaDelQMYZniCkcGk"
                    },
                    params: {
                        event_type_id: 1,
                        count: 10
                    }
                };
                const res = await axios.get("https://quote-cdn.uni247.xyz/api/quote/vsb/overall-matches/light/v1/", config);
                setProduct(res.data.data);
                const promises = res.data.data.map(async (item) => {
                    const res2 = await axios.get(`https://quote-cdn.uni247.xyz/api/quote/get-live-score/?event_id=${item.event_id}&summary=1&lang=vi`);
                    return res2.data.sport_event;
                });
                const resultArray = await Promise.all(promises);
                setFinalData(resultArray);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);
    if (url.length != 39) return;
    return (
        <>
            <Header profile={profile} />
            {loading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            {swiperParams && notify && (
                <div className="box-slide" style={{ overflow: "hidden" }}>
                    <Swiper {...swiperParams}>
                        {notify.map((item, index) => (
                            <>
                                {index > 1 && item.title !== "marquee" && item.title !== "popup" && item.isShow === true ? (
                                    <SwiperSlide>
                                        <img alt={item.title.replace("}", "").replace("{", "")} src={item.image} />
                                    </SwiperSlide>
                                ) : null}
                            </>
                        ))}
                    </Swiper>
                </div>
            )}
            {notify ? (
                <>
                    {notify[0].isShow === true && notify[0].title === "marquee" ? (
                        <div className="header-notify">
                            <div className="container d-flex">
                                <div className="notify-title">
                                    <VolumeUpIcon />
                                </div>
                                <marquee>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: notify[0].content
                                        }}
                                    />
                                </marquee>
                            </div>
                        </div>
                    ) : null}
                </>
            ) : null}
            <div style={{ background: "#fff" }}>
                <div style={{ padding: "50px 0" }}>
                    <div className="container">
                        <Swiper {...swiperBanner}>
                            <SwiperSlide onClick={() => navigate("/promotion")}>
                                <img src={require("../../statics/promo/21b4e5cd-3e35-48ed-bc4d-f1b077e300dd.webp")} />
                            </SwiperSlide>
                            <SwiperSlide onClick={() => navigate("/promotion")}>
                                <img src={require("../../statics/promo/296b92bd-a6c6-434f-abdc-77ba91d3670c.webp")} />
                            </SwiperSlide>
                            <SwiperSlide onClick={() => navigate("/promotion")}>
                                <img src={require("../../statics/promo/8b67bb35-c6c2-4c54-9aaf-e6b5a92a964a.webp")} />
                            </SwiperSlide>
                            <SwiperSlide onClick={() => navigate("/promotion")}>
                                <img src={require("../../statics/promo/8d46f3a6-3adb-417a-b209-769e9a3a1a52.webp")} />
                            </SwiperSlide>
                            <SwiperSlide onClick={() => navigate("/promotion")}>
                                <img src={require("../../statics/promo/ce08e3bb-4347-4a84-8d67-5654780f8f3f.webp")} />
                            </SwiperSlide>
                            <SwiperSlide onClick={() => navigate("/promotion")}>
                                <img src={require("../../statics/promo/dd157436-672a-4c79-9c7f-d7ce9e4e82ab.webp")} />
                            </SwiperSlide>
                            <SwiperSlide onClick={() => navigate("/promotion")}>
                                <img src={require("../../statics/promo/e3c19ee7-dec0-48c6-bb45-6fdd5ba7b0f6.webp")} />
                            </SwiperSlide>
                            <SwiperSlide onClick={() => navigate("/promotion")}>
                                <img src={require("../../statics/promo/fdc0d6d4-366d-492d-873e-4b3d7483e9f0.webp")} />
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
                <div className="bg-home-sports">
                    <div className="score-board-wrap container">
                        <Swiper {...swiperSports}>
                            {finalData && product && 
                                finalData.map((item, index) => (
                                    <SwiperSlide>
                                        <div className="box-sports-home">
                                            <div className="box-sports-home_title">{item?.tournament?.name}</div>
                                            <div className="box-sports-home_match">
                                                <div>
                                                    <img
                                                        src={`https://static.uni247.xyz/images-v3/${item?.competitors?.competitor[0]?.id?.replaceAll(":", "")}.svg`}
                                                        onError={(e) => handleImageError(e, 0)}
                                                    />
                                                    <p>{item?.competitors?.competitor[0].name}</p>
                                                </div>
                                                <div>
                                                    {formatTime(item?.scheduled)}
                                                    <br />
                                                    {formatDay(item?.scheduled)}
                                                </div>
                                                <div>
                                                    <img
                                                        src={`https://static.uni247.xyz/images-v3/${item?.competitors?.competitor[1]?.id?.replaceAll(":", "")}.svg`}
                                                        onError={(e) => handleImageError(e, 1)}
                                                    />
                                                    <p>{item?.competitors?.competitor[1].name}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <Link className="cuocngay" to="/sports">
                                            Cược ngay
                                        </Link>
                                    </SwiperSlide>
                                ))}
                        </Swiper>
                    </div>
                </div>
                <div style={{ padding: "30px 0" }}>
                    <div className="container">
                        <div className="home-link-list flex" style={{ display: "flex", gap: "20px" }}>
                            <div className="grid" style={{ width: "45.2%" }}>
                                <div>
                                    <a href="https://AE888sp.com/" target="_blank">
                                        <img src={require("../../statics/home-link-cover1.jpg")} />
                                    </a>
                                </div>
                                <div>
                                    <a href="#" target="_blank">
                                        <img src={require("../../statics/home-link-cover2.jpg")} />
                                    </a>
                                </div>
                                <div>
                                    <a href="https://aeminiload.us/rut-tien/" target="_blank">
                                        <img src={require("../../statics/home-link-cover3.jpg")} />
                                    </a>
                                </div>
                                <div>
                                    <a href="https://bit.ly/tiengruoi" target="_blank">
                                        <img src={require("../../statics/home-link-cover4.jpg")} />
                                    </a>
                                </div>
                            </div>
                            <div style={{ width: "54.8%" }}>
                                <a href="https://aeminiload.us/rut-tien/" target="_blank">
                                    <img src={require("../../statics/home-link-cover5.jpg")} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Home;
